import React from 'react';
import { motion } from 'framer-motion';
import Container from '../../common/container';

const WhatsAppCheckoutSVG = () => (
  <svg viewBox="0 0 1200 800" xmlns="http://www.w3.org/2000/svg" className="w-full h-full">
    {/* Gradients & Patterns */}
    <defs>
      <linearGradient id="bg-gradient" x1="0" y1="0" x2="100%" y2="100%">
        <stop offset="0%" stopColor="#f7f7f7"/>
        <stop offset="100%" stopColor="#e5e5e5"/>
      </linearGradient>
      <pattern id="dots" x="0" y="0" width="30" height="30" patternUnits="userSpaceOnUse">
        <circle cx="2" cy="2" r="1" fill="#075E54" fillOpacity="0.07"/>
      </pattern>
      <filter id="glow">
        <feGaussianBlur stdDeviation="3" result="blur"/>
        <feComposite in="SourceGraphic" in2="blur" operator="over"/>
      </filter>
    </defs>

    {/* Dynamic Background */}
    <rect width="1200" height="800" fill="url(#bg-gradient)"/>
    <rect width="1200" height="800" fill="url(#dots)"/>

    {/* Staggered Layout with Perspective */}
    <g transform="translate(100, 50)">
      {/* Insurance Premium Mockup - Left Tilted */}
      <g transform="translate(0, 100) rotate(-2)">
        <rect width="300" height="540" rx="24" fill="#fff" filter="drop-shadow(0px 4px 12px rgba(0,0,0,0.1))"/>
        <rect width="300" height="540" rx="24" fill="#E5DDD5" opacity="0.95"/>
        
        {/* Header */}
        <linearGradient id="header-gradient-1" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="#128C7E"/>
          <stop offset="100%" stopColor="#075E54"/>
        </linearGradient>
        <rect width="300" height="60" fill="url(#header-gradient-1)" rx="24 24 0 0"/>
        <circle cx="35" cy="30" r="15" fill="#fff"/>
        <text x="60" y="35" fill="white" fontSize="14" fontWeight="500" fontFamily="system-ui">Premium Insurance</text>
        
        {/* Chat Content */}
        <g transform="translate(20, 80)">
          <rect width="260" height="140" rx="12" fill="white" filter="drop-shadow(0px 1px 3px rgba(0,0,0,0.1))"/>
          <text x="15" y="30" fill="#1a1a1a" fontSize="14" fontFamily="system-ui">Jambo Kariuki,</text>
          <text x="15" y="55" fill="#1a1a1a" fontSize="14" fontFamily="system-ui">Your car insurance premium for</text>
          <text x="15" y="80" fill="#1a1a1a" fontSize="14" fontFamily="system-ui">December 2024 is now due.</text>
          <text x="15" y="110" fill="#075E54" fontSize="15" fontWeight="600" fontFamily="system-ui">Amount: KES 4,500</text>
          <text x="190" y="125" fill="#666" fontSize="11" fontFamily="system-ui">10:30 AM ✓✓</text>
        </g>
        
        {/* Payment Button */}
        <g transform="translate(20, 240)">
          <rect width="260" height="60" rx="8" fill="#25D366"/>
          <text x="85" y="38" fill="white" fontSize="15" fontWeight="500" fontFamily="system-ui">Pay KES 4,500</text>
        </g>
      </g>

      {/* Wholesale Payment Mockup - Center Elevated */}
      <g transform="translate(380, 50)">
        <rect width="320" height="580" rx="26" fill="#fff" filter="drop-shadow(0px 8px 24px rgba(0,0,0,0.15))"/>
        <rect width="320" height="580" rx="26" fill="#E5DDD5" opacity="0.95"/>
        
        {/* Header */}
        <linearGradient id="header-gradient-2" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="#128C7E"/>
          <stop offset="100%" stopColor="#075E54"/>
        </linearGradient>
        <rect width="320" height="65" fill="url(#header-gradient-2)" rx="26 26 0 0"/>
        <circle cx="40" cy="32.5" r="16" fill="#fff"/>
        <text x="70" y="37.5" fill="white" fontSize="15" fontWeight="500" fontFamily="system-ui">Metro Wholesalers</text>
        
        {/* Chat Content */}
        <g transform="translate(25, 85)">
          <rect width="270" height="180" rx="12" fill="white" filter="drop-shadow(0px 1px 3px rgba(0,0,0,0.1))"/>
          <text x="15" y="30" fill="#1a1a1a" fontSize="14" fontFamily="system-ui">Hi Aaden's Store,</text>
          <text x="15" y="60" fill="#1a1a1a" fontSize="14" fontFamily="system-ui">Please complete your order.</text>
          <text x="15" y="85" fill="#1a1a1a" fontSize="14" fontFamily="system-ui">Order #45678:</text>
          <text x="15" y="90" fill="#1a1a1a" fontSize="14" fontFamily="system-ui">Subtotal: KES 25,000</text>
          <text x="15" y="115" fill="#25D366" fontSize="14" fontFamily="system-ui">Loyalty Discount: 10%</text>
          <text x="15" y="145" fill="#075E54" fontSize="15" fontWeight="600" fontFamily="system-ui">Final Amount: KES 22,500</text>
          <text x="200" y="165" fill="#666" fontSize="11" fontFamily="system-ui">11:15 AM ✓✓</text>
        </g>
        
        {/* Payment Button */}
        <g transform="translate(25, 285)">
          <rect width="270" height="65" rx="8" fill="#25D366"/>
          <text x="75" y="40" fill="white" fontSize="15" fontWeight="500" fontFamily="system-ui">Complete Payment</text>
        </g>
      </g>

      {/* NGO Donation Mockup - Right Tilted */}
      <g transform="translate(780, 100) rotate(2)">
        <rect width="300" height="540" rx="24" fill="#fff" filter="drop-shadow(0px 4px 12px rgba(0,0,0,0.1))"/>
        <rect width="300" height="540" rx="24" fill="#E5DDD5" opacity="0.95"/>
        
        {/* Header */}
        <linearGradient id="header-gradient-3" x1="0" y1="0" x2="0" y2="1">
          <stop offset="0%" stopColor="#128C7E"/>
          <stop offset="100%" stopColor="#075E54"/>
        </linearGradient>
        <rect width="300" height="60" fill="url(#header-gradient-3)" rx="24 24 0 0"/>
        <circle cx="35" cy="30" r="15" fill="#fff"/>
        <text x="60" y="35" fill="white" fontSize="14" fontWeight="500" fontFamily="system-ui">GreenEarth</text>
        
        {/* Chat Content */}
        <g transform="translate(20, 80)">
          <rect width="260" height="160" rx="12" fill="white" filter="drop-shadow(0px 1px 3px rgba(0,0,0,0.1))"/>
          <text x="15" y="30" fill="#1a1a1a" fontSize="14" fontFamily="system-ui">Dear Mutiso,</text>
          <text x="15" y="55" fill="#1a1a1a" fontSize="14" fontFamily="system-ui">Thank you for choosing to help.</text>
          <text x="15" y="80" fill="#1a1a1a" fontSize="14" fontFamily="system-ui">Your donation will</text>
          <text x="15" y="105" fill="#1a1a1a" fontSize="14" fontFamily="system-ui">provide fertilizer support.</text>
          <text x="15" y="135" fill="#075E54" fontSize="15" fontWeight="600" fontFamily="system-ui">Amount: KES 300</text>
          <text x="190" y="145" fill="#666" fontSize="11" fontFamily="system-ui">2:45 PM ✓✓</text>
        </g>
        
        {/* Donation Button */}
        <g transform="translate(20, 260)">
          <rect width="260" height="60" rx="8" fill="#25D366"/>
          <text x="90" y="38" fill="white" fontSize="15" fontWeight="500" fontFamily="system-ui">Donate KES 300</text>
        </g>
      </g>
    </g>

    {/* Connecting Elements */}
    <g transform="translate(0, 0)" opacity="0.5">
      <path d="M300 350 Q 600 250 900 350" stroke="#25D366" strokeWidth="2" fill="none" strokeDasharray="4 4"/>
      <circle cx="300" cy="350" r="4" fill="#25D366"/>
      <circle cx="600" cy="250" r="4" fill="#25D366"/>
      <circle cx="900" cy="350" r="4" fill="#25D366"/>
    </g>

    {/* Subtle Decorative Elements */}
    <g opacity="0.1">
      <circle cx="50" cy="50" r="100" fill="#25D366"/>
      <circle cx="1150" cy="750" r="150" fill="#128C7E"/>
    </g>
  </svg>
);

const WhatsAppOperationsSVG = () => (
  <svg viewBox="0 0 800 600" xmlns="http://www.w3.org/2000/svg">
    {/* Background with gradient */}
    <defs>
      <linearGradient id="bg-grad" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#ffffff"/>
        <stop offset="100%" stopColor="#f8fafc"/>
      </linearGradient>
      <linearGradient id="card-grad" x1="0" y1="0" x2="0" y2="1">
        <stop offset="0%" stopColor="#ffffff"/>
        <stop offset="100%" stopColor="#f9fafb"/>
      </linearGradient>
      <filter id="shadow">
        <feDropShadow dx="0" dy="2" stdDeviation="4" floodOpacity="0.08"/>
      </filter>
    </defs>

    {/* Main Dashboard Container */}
    <rect width="800" height="600" fill="url(#bg-grad)" rx="12"/>

    {/* Top Navigation Bar */}
    <rect x="0" y="0" width="800" height="60" fill="white" filter="url(#shadow)" rx="12"/>
    <text x="30" y="38" fill="#111827" fontSize="20" fontWeight="600" fontFamily="system-ui">Dashboard</text>
    
    {/* Stats Cards Row */}
    <g transform="translate(24, 80)">
      {/* Total Orders Card */}
      <rect width="180" height="100" fill="url(#card-grad)" rx="8" filter="url(#shadow)"/>
      <text x="20" y="30" fill="#6b7280" fontSize="14" fontFamily="system-ui">Total Orders</text>
      <text x="20" y="65" fill="#111827" fontSize="24" fontWeight="600" fontFamily="system-ui">1,284</text>
      <path d="M20 80 L40 70 L60 77 L80 65" stroke="#10b981" strokeWidth="2" fill="none"/>

      {/* Revenue Card */}
      <g transform="translate(200, 0)">
        <rect width="180" height="100" fill="url(#card-grad)" rx="8" filter="url(#shadow)"/>
        <text x="20" y="30" fill="#6b7280" fontSize="14" fontFamily="system-ui">Revenue</text>
        <text x="20" y="65" fill="#111827" fontSize="24" fontWeight="600" fontFamily="system-ui">KES 845K</text>
        <path d="M20 80 L40 65 L60 72 L80 60" stroke="#10b981" strokeWidth="2" fill="none"/>
      </g>

      {/* Inventory Items Card */}
      <g transform="translate(400, 0)">
        <rect width="180" height="100" fill="url(#card-grad)" rx="8" filter="url(#shadow)"/>
        <text x="20" y="30" fill="#6b7280" fontSize="14" fontFamily="system-ui">Inventory Items</text>
        <text x="20" y="65" fill="#111827" fontSize="24" fontWeight="600" fontFamily="system-ui">326</text>
        <path d="M20 80 L40 75 L60 73 L80 78" stroke="#f59e0b" strokeWidth="2" fill="none"/>
      </g>
    </g>

    {/* Main Chart Area */}
    <g transform="translate(24, 200)">
      <rect width="500" height="300" fill="url(#card-grad)" rx="8" filter="url(#shadow)"/>
      <text x="20" y="30" fill="#111827" fontSize="16" fontWeight="600" fontFamily="system-ui">Orders Overview</text>
      
      {/* Chart Grid */}
      <g transform="translate(40, 60)" stroke="#e5e7eb" strokeWidth="1">
        <line x1="0" y1="0" x2="420" y2="0"/>
        <line x1="0" y1="60" x2="420" y2="60"/>
        <line x1="0" y1="120" x2="420" y2="120"/>
        <line x1="0" y1="180" x2="420" y2="180"/>
      </g>

      {/* Chart Data */}
      <g transform="translate(40, 240)">
        <path d="M0 0 L70 -40 L140 -80 L210 -100 L280 -140 L350 -160" 
              stroke="#10b981" strokeWidth="3" fill="none"/>
        <path d="M0 0 L70 -40 L140 -80 L210 -100 L280 -140 L350 -160" 
              stroke="#10b981" strokeWidth="12" strokeOpacity="0.1" fill="none"/>
      </g>
    </g>

    {/* Recent Orders Panel */}
    <g transform="translate(544, 200)">
      <rect width="232" height="300" fill="url(#card-grad)" rx="8" filter="url(#shadow)"/>
      <text x="20" y="30" fill="#111827" fontSize="16" fontWeight="600" fontFamily="system-ui">Recent Orders</text>
      
      {/* Order Items */}
      {[0, 1, 2, 3, 4].map(i => (
        <g transform={`translate(20, ${70 + i * 50})`}>
          <circle cx="12" cy="12" r="12" fill="#f3f4f6"/>
          <text x="35" y="12" fill="#111827" fontSize="14" fontWeight="500" fontFamily="system-ui">Order #{1234 + i}</text>
          <text x="35" y="28" fill="#6b7280" fontSize="12" fontFamily="system-ui">KES {(Math.random() * 10000).toFixed(0)}</text>
        </g>
      ))}
    </g>

    {/* Quick Actions */}
    <g transform="translate(24, 520)">
      {/* New Order Button */}
      <rect width="150" height="40" fill="#10b981" rx="6"/>
      <text x="75" y="25" fill="white" fontSize="14" fontWeight="500" fontFamily="system-ui" textAnchor="middle">New Order</text>

      {/* Export Button */}
      <g transform="translate(170, 0)">
        <rect width="150" height="40" fill="#f3f4f6" rx="6"/>
        <text x="75" y="25" fill="#374151" fontSize="14" fontWeight="500" fontFamily="system-ui" textAnchor="middle">Export Data</text>
      </g>
    </g>

    {/* Notifications */}
    <g transform="translate(720, 10)">
      <circle cx="20" cy="20" r="16" fill="#f3f4f6"/>
      <circle cx="20" cy="20" r="4" fill="#ef4444"/>
    </g>
  </svg>
);

const InventoryAnalysisSVG = () => (
  <svg viewBox="0 0 600 400" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <filter id="glow">
        <feGaussianBlur stdDeviation="2" result="coloredBlur"/>
        <feMerge>
          <feMergeNode in="coloredBlur"/>
          <feMergeNode in="SourceGraphic"/>
        </feMerge>
      </filter>
      <linearGradient id="connection-gradient" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stopColor="#4f46e5" stopOpacity="0.2"/>
        <stop offset="50%" stopColor="#4f46e5" stopOpacity="0.6"/>
        <stop offset="100%" stopColor="#4f46e5" stopOpacity="0.2"/>
        <animate attributeName="x1" from="0%" to="100%" dur="3s" repeatCount="indefinite"/>
        <animate attributeName="x2" from="100%" to="200%" dur="3s" repeatCount="indefinite"/>
      </linearGradient>
    </defs>

    {/* Center Hub */}
    <circle cx="300" cy="200" r="40" fill="#4f46e5" filter="url(#glow)" opacity="0.9">
      <animate attributeName="r" values="40;42;40" dur="2s" repeatCount="indefinite"/>
    </circle>

    {/* Orbital Nodes */}
    {[0, 60, 120, 180, 240, 300].map((angle, i) => {
      const x = 300 + Math.cos(angle * Math.PI / 180) * 120;
      const y = 200 + Math.sin(angle * Math.PI / 180) * 120;
      return (
        <g key={i}>
          <circle 
            cx={x} 
            cy={y} 
            r="25" 
            fill="#6366f1" 
            opacity="0.8"
            filter="url(#glow)"
          >
            <animate 
              attributeName="r" 
              values="25;27;25" 
              dur="2s" 
              begin={`${i * 0.3}s`}
              repeatCount="indefinite"
            />
          </circle>
          <line 
            x1="300" 
            y1="200" 
            x2={x} 
            y2={y} 
            stroke="url(#connection-gradient)" 
            strokeWidth="2"
          >
            <animate 
              attributeName="strokeWidth" 
              values="2;3;2" 
              dur="2s" 
              begin={`${i * 0.3}s`}
              repeatCount="indefinite"
            />
          </line>
        </g>
      );
    })}

    {/* Secondary Connections */}
    {[30, 90, 150, 210, 270, 330].map((angle, i) => {
      const x = 300 + Math.cos(angle * Math.PI / 180) * 180;
      const y = 200 + Math.sin(angle * Math.PI / 180) * 180;
      return (
        <g key={i}>
          <circle 
            cx={x} 
            cy={y} 
            r="20" 
            fill="#818cf8" 
            opacity="0.6"
            filter="url(#glow)"
          >
            <animate 
              attributeName="r" 
              values="20;22;20" 
              dur="2s" 
              begin={`${i * 0.4}s`}
              repeatCount="indefinite"
            />
          </circle>
          <line 
            x1="300" 
            y1="200" 
            x2={x} 
            y2={y} 
            stroke="url(#connection-gradient)" 
            strokeWidth="1.5"
            opacity="0.6"
          />
        </g>
      );
    })}

    {/* Floating Success Metrics */}
    {[45, 165, 285].map((angle, i) => {
      const x = 300 + Math.cos(angle * Math.PI / 180) * 150;
      const y = 200 + Math.sin(angle * Math.PI / 180) * 150;
      return (
        <text 
          key={i}
          x={x} 
          y={y} 
          fill="#4f46e5" 
          fontSize="14" 
          textAnchor="middle" 
          opacity="0.8"
        >
          {i === 0 ? "+28% ROI" : i === 1 ? "500+" : "95% Match"}
          <animate 
            attributeName="opacity" 
            values="0.8;1;0.8" 
            dur="3s" 
            begin={`${i * 0.5}s`}
            repeatCount="indefinite"
          />
        </text>
      );
    })}
  </svg>
);

export default function ConversionSection({ showDeadstock = false, initialData }) {
  const getImagePath = (path) => {
    return initialData?.data?.storage_url 
      ? `${initialData.data.storage_url}${path.replace('/static/frontend/', '')}`
      : path;
  };

  const featuresData = {
    default: [
      {
        id: 1,
        label: "WhatsApp Catalog",
        title: "Create Your WhatsApp Store",
        description: "Showcase your products and services directly on WhatsApp. Build an interactive catalog that customers can browse anytime, enabling 24/7 shopping convenience.",
        image: getImagePath("/static/frontend/images/site/social_commerce.png"),
        imageAlt: "WhatsApp Catalog Interface",
        ctaText: "Explore Catalog Features",
        isSvg: false
      },
      {
        id: 2,
        label: "Direct Checkout",
        title: "Seamless Payment Experience",
        description: "Enable customers to complete purchases directly within WhatsApp. Integrate with popular payment methods and process transactions securely—all without leaving the chat.",
        image: null, // We'll use SVG instead
        imageAlt: "WhatsApp Checkout Flow",
        ctaText: "See Payment Options",
        isSvg: true,
        SvgComponent: WhatsAppCheckoutSVG
      },
      {
        id: 3,
        label: "Order Management",
        title: "Streamline Your Operations",
        description: "Manage orders, track inventory, and handle customer requests all from one dashboard. Automate routine tasks and focus on growing your business.",
        image: null,
        imageAlt: "Order Management Dashboard",
        ctaText: "View Dashboard Features",
        isSvg: true,
        SvgComponent: WhatsAppOperationsSVG
      }
    ],
    deadstock: [
      {
        id: 1,
        label: "Smart Inventory Analysis",
        title: "Identify Optimization Opportunities",
        description: "Our AI-powered system analyzes your inventory performance across locations, identifying slow-moving stock and recommending optimal reallocation strategies to maximize sales potential.",
        image: null,
        imageAlt: "Inventory Analysis Dashboard",
        ctaText: "See How It Works",
        isSvg: true,
        SvgComponent: InventoryAnalysisSVG
      },
      {
        id: 2,
        label: "Business Community Network",
        title: "Connect with Relevant Retailers",
        description: "Join our network of 500+ retailers and tap into demand across different locations. Our matching algorithm finds the perfect stores for your excess inventory, ensuring better returns than traditional discounting.",
        image: getImagePath("/static/frontend/images/site/catalog.png"),
        imageAlt: "Business Network Interface",
        ctaText: "Explore Network",
        isSvg: true,
        SvgComponent: InventoryAnalysisSVG
      },
      {
        id: 3,
        label: "Inventory Management",
        title: "Free Point of Sale System",
        description: "Get real-time visibility into your inventory with our free POS system. Track stock levels, monitor performance, and make data-driven decisions to prevent future deadstock accumulation.",
        image: getImagePath('/static/frontend/images/site/appdemo.png'),
        imageAlt: "POS System Interface",
        ctaText: "Try Free POS"
      }
    ]
  };

  const titles = {
    default: {
      label: "Features",
      title: "Transform How You Sell",
      description: "Unlock the power of social commerce with features designed to help you sell smarter, not harder. Connect with customers where they already are."
    },
    deadstock: {
      label: "Smart Solutions",
      title: "Optimize Your Inventory",
      description: "Turn your KES 233 Billion deadstock challenge into an opportunity. Our platform helps you maintain profits without resorting to heavy discounting by connecting you with stores where your products are in demand."
    }
  };

  const activeFeatures = featuresData[showDeadstock ? 'deadstock' : 'default'];
  const activeTitle = titles[showDeadstock ? 'deadstock' : 'default'];

  return (
    <section className="bg-[#faf7fa] dark:bg-gray-900 py-24 relative overflow-hidden">
      {/* Rest of your existing JSX structure, but using activeFeatures and activeTitle */}
      <div className="absolute inset-0 overflow-hidden">
        <div className="absolute top-0 left-1/2 -translate-x-1/2 w-full max-w-[1500px] h-[500px] bg-gradient-to-b from-primary/[0.03] to-transparent" />
        <div className="absolute -top-1/2 -right-1/4 w-96 h-96 bg-primary/5 rounded-full blur-3xl" />
        <div className="absolute -bottom-1/2 -left-1/4 w-96 h-96 bg-primary/5 rounded-full blur-3xl" />
      </div>

      <Container className="relative">
        {/* Header Section with Improved Typography */}
        <motion.div 
          className="relative mx-auto mb-32 max-w-2xl text-center"
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
        >
          <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-primary bg-primary/10 rounded-full mb-6">
            {activeTitle.label}
          </span>
          <h2 className="mb-6 text-4xl font-bold tracking-tight text-gray-900 dark:text-white md:text-5xl">
            {activeTitle.title}
          </h2>
          <p className="text-lg text-gray-600 dark:text-gray-300 leading-relaxed">
            {activeTitle.description}
          </p>
        </motion.div>

        {/* Features Grid with Improved Image Presentation */}
        <div className="space-y-32">
          {activeFeatures.map((feature, index) => (
            <motion.div
              key={feature.id}
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true, margin: "-100px" }}
              className={`grid items-center grid-cols-1 gap-12 lg:grid-cols-2 lg:gap-20`}
            >
              {/* Text Content */}
              <motion.div
                initial={{ x: index % 2 === 0 ? -30 : 30, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className={`space-y-8 ${index % 2 === 1 ? 'lg:order-2' : ''}`}
              >
                <div className="space-y-6">
                  <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-primary bg-primary/10 rounded-full">
                    {feature.label}
                  </span>
                  <h3 className="text-3xl font-bold tracking-tight text-gray-900 dark:text-white">
                    {feature.title}
                  </h3>
                  <p className="text-gray-600 dark:text-gray-300 leading-relaxed">
                    {feature.description}
                  </p>
                  
                  <motion.button 
                    className="inline-flex items-center text-primary hover:text-primary-dark transition-colors group"
                    whileHover={{ x: 5 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                  >
                    <span className="font-medium">{feature.ctaText}</span>
                    <svg className="w-5 h-5 ml-2 transition-transform group-hover:translate-x-1" 
                         fill="none" 
                         stroke="currentColor" 
                         viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                  </motion.button>
                </div>
              </motion.div>

              {/* Image Content with Improved Presentation */}
              <motion.div
                initial={{ x: index % 2 === 0 ? 30 : -30, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{ duration: 0.5, delay: 0.3 }}
                className={`relative ${index % 2 === 1 ? 'lg:order-1' : ''}`}
              >
                <div className="relative">
                  <motion.div 
                    className="relative z-10 overflow-hidden bg-white rounded-3xl shadow-xl"
                    whileHover={{ y: -8 }}
                    transition={{ type: "spring", stiffness: 300, damping: 25 }}
                  >
                    {/* Replaced AspectRatio with CSS padding trick */}
                    <div className="relative w-full pt-[75%]">
                    {feature.isSvg ? (
                      <div className="absolute inset-0 p-4">
                        <feature.SvgComponent />
                      </div>
                    ) : (
                      <>
                        <img
                          src={feature.image}
                          alt={feature.imageAlt}
                          className="absolute inset-0 object-cover w-full h-full transition-transform duration-300 group-hover:scale-105"
                          loading="lazy"
                        />
                        <div className="absolute inset-0 pointer-events-none bg-gradient-to-t from-black/5 to-transparent" />
                      </>
                    )}
                    </div>
                  </motion.div>

                  {/* Enhanced background effects */}
                  <div className="absolute -inset-4 bg-gradient-to-tr from-primary/10 via-primary/5 to-transparent rounded-[3rem] blur-2xl -z-10" />
                  <div className={`absolute w-32 h-32 rounded-full blur-3xl transition-opacity duration-500
                    ${index % 2 === 0 ? 
                      'bg-primary/10 -right-8 -bottom-8' : 
                      'bg-primary/10 -left-8 -top-8'}`}
                  />
                </div>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </Container>
    </section>
  );
}