import React, { useState, useEffect } from 'react';
import Container from '../../common/container';
import Button from '../../common/button';
import ReactCountryFlag from "react-country-flag";

export default function Hero({ showDeadstock, setShowDeadstock, initialData }) {
    // state to control confetti visibility
    const [showConfetti, setShowConfetti] = useState(true);

    const getImagePath = (path) => {
        // Ensure the storage URL is used if available
        if (initialData?.data?.storage_url) {
            // Remove any redundant prefixes like '/static/frontend/' to prevent duplication
            const sanitizedPath = path.startsWith('/static/frontend/')
                ? path.replace('/static/frontend/', '')
                : path;
            return `${initialData.data.storage_url}${sanitizedPath}`;
        }
        // Fallback to the original path if storage_url is unavailable
        return path;
    };

    // state for image carousel
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const defaultImages = [
        getImagePath('/static/frontend/images/site/social_commerce.png'),
        getImagePath('/static/frontend/images/site/social_commerce_catalogs.png')
    ];

    // Effect to cycle through images for default case
    useEffect(() => {
        // Only run the carousel if we're showing the default content
        if (!showDeadstock) {
            const interval = setInterval(() => {
                setCurrentImageIndex(prevIndex => (prevIndex + 1) % defaultImages.length);
            }, 5000); // Change image every 3 seconds
            
            return () => clearInterval(interval);
        }
    }, [showDeadstock, defaultImages.length]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowConfetti(false);
        }, 3000); // Show confetti for 3 seconds
        
        return () => clearTimeout(timer);
    }, []);
    
    const content = {
      default: {
        title: 'Engage Clients On The World\'s',
        titleHighlight: 'Most Popular Apps',
        description: "Revolutionize how your business connects, sells, and grows by integrating seamlessly with the world's most popular messaging platforms. Leverage WhatsApp & Telegram on SokoGram to build meaningful relationships with clients and grow your business.",
        trustText: "Built atop the world's ",
        trustHighlight: 'most popular',
        trustEnd: ' messaging platforms.',
        image: defaultImages[currentImageIndex],
        imageAlt: 'WymBee Dashboard'
      },
      deadstock: {
        title: 'One-Stop Solution To Your',
        titleHighlight: 'Deadstock Problem',
        description: 'Transform your excess inventory into opportunity. Our intelligent platform connects you with potential buyers, optimizes pricing, and streamlines the entire redistribution process for maximum recovery.',
        trustText: 'Successfully redistributed ',
        trustHighlight: 'KES 5M+ worth',
        trustEnd: ' of deadstock inventory across Kenya.',
        image: getImagePath('/static/frontend/images/site/appdemo.png'),
        imageAlt: 'WymBee App'
      }
    };
  
    const activeContent = content[showDeadstock ? 'deadstock' : 'default'];

    const [userLocation, setUserLocation] = useState({
        country: null,
        country_code: null,
        loading: true,
        error: null
    });
    useEffect(() => {
        const fetchLocation = async () => {
          try {
            // First attempt with ipapi.co
            const response = await fetch('https://ipapi.co/json/');
            if (!response.ok) throw new Error('Location service unavailable');
            const data = await response.json();
            setUserLocation({
              country: data.country_name,
              country_code: data.country_code,
              loading: false,
              error: null
            });
          } catch (error) {
            console.error('ipapi.co failed, trying ipinfo.io:', error);
            try {
              // Fallback to ipinfo.io
              const response = await fetch('https://ipinfo.io/json');
              if (!response.ok) throw new Error('Location service unavailable');
              const data = await response.json();
              setUserLocation({
                country: data.country,
                country_code: data.country,
                loading: false,
                error: null
              });
            } catch (fallbackError) {
              console.error('Both services failed:', fallbackError);
              setUserLocation(prev => ({
                ...prev,
                loading: false,
                error: 'Unable to detect location'
              }));
            }
          }
        };
      
        fetchLocation();
    }, []);
  
    return (
        <Container className="py-12 md:py-20 relative">
            <style>
                {`
                    @keyframes confettiFall {
                        0% { transform: translateY(-10px) rotate(0deg); opacity: 1; }
                        100% { transform: translateY(400px) rotate(360deg); opacity: 0; }
                    }
                `}
            </style>
            
            {/* Confetti container - only show for 5 seconds */}
            {showConfetti && (
                <div className="absolute inset-0 pointer-events-none overflow-hidden">
                    {[...Array(20)].map((_, i) => (
                        <div
                            key={i}
                            className="absolute w-2 h-2 rounded-full"
                            style={{
                                left: `${Math.random() * 100}%`,
                                top: `-10px`,
                                animation: `confettiFall ${2 + Math.random() * 2}s linear infinite`,
                                animationDelay: `${Math.random() * 3}s`,
                                backgroundColor: [
                                    '#7E22CE',  // purple
                                    '#3B82F6',  // blue
                                    '#EAB308',  // yellow
                                    '#10B981',  // green
                                ][Math.floor(Math.random() * 4)],
                                opacity: 0.3
                            }}
                        />
                    ))}
                </div>
            )}
            {/* Toggle Switch */}
            <div className="fixed top-24 right-4 flex items-center gap-3 bg-white/90 backdrop-blur-lg p-3 rounded-2xl shadow-lg border border-gray-100 z-50 hover:shadow-xl transition-shadow duration-300">
                <span className={`text-sm transition-colors duration-300 ${!showDeadstock ? 'text-primary font-medium' : 'text-gray-500'}`}>
                    Social Commerce
                </span>
                <button
                    onClick={() => setShowDeadstock(!showDeadstock)}
                    className="relative w-14 h-7 rounded-full transition-colors duration-300"
                    style={{
                        backgroundColor: showDeadstock ? '#7E22CE' : '#F3F4F6'
                    }}
                >
                    <div
                        className="absolute top-1 left-1 w-5 h-5 bg-white rounded-full shadow-md transition-transform duration-300 ease-in-out"
                        style={{
                            transform: showDeadstock ? 'translateX(28px)' : 'translateX(0)'
                        }}
                    />
                </button>
                <span className={`text-sm transition-colors duration-300 ${showDeadstock ? 'text-primary font-medium' : 'text-gray-500'}`}>
                    Deadstock Solution
                </span>
            </div>

            <div className="flex flex-col lg:flex-row items-center gap-8 lg:gap-16 pt-8 md:pt-12">
                <div className="max-w-xl">
                    <h1 className="font-bold mb-4 md:mb-6">
                        <div className="text-4xl md:text-5xl lg:text-6xl transform transition-transform duration-500 hover:scale-[1.02]">
                            {activeContent.title}
                        </div>
                        <div className="text-3xl md:text-4xl lg:text-5xl text-primary mt-3 transform transition-transform duration-500 hover:scale-[1.02]">
                            {activeContent.titleHighlight}
                        </div>
                    </h1>
                    <p className="text-gray-600 text-base md:text-xl mb-6 md:mb-8">
                        {activeContent.description}
                    </p>
                    {showDeadstock ? (
                        <a href="https://play.google.com/store/apps/details?id=com.app.wymbee" target="_blank" rel="noopener noreferrer">
                            <button 
                                className="w-full md:w-auto text-lg px-6 md:px-8 py-3 md:py-4 bg-primary hover:bg-primary-hover text-white rounded transition-colors duration-200 flex items-center justify-center gap-2"
                                >
                                <span>Download App</span>
                                <svg 
                                    viewBox="0 0 24 24" 
                                    className="w-6 h-6 fill-current"
                                >
                                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 14v-4H8l4-4 4 4h-3v4h-2z"/>
                                </svg>
                            </button>
                        </a>
                    ) : (
                        <a href="https://calendar.app.google/BXgPp5Ks5SyhBHLbA" target="_blank" rel="noopener noreferrer">
                            <Button className="w-full md:w-auto text-lg px-6 md:px-8 py-3 md:py-4 bg-[#25D366] hover:bg-[#128C7E] text-white rounded transition-colors duration-200">
                            Book Demo Session
                            </Button>
                        </a>
                    )}
                    <div className="mt-6 md:mt-8 text-gray-700 text-sm md:text-base">
                        {activeContent.trustText}
                        <span className="text-primary font-bold">
                        {activeContent.trustHighlight}
                        </span>
                        {activeContent.trustEnd}
                    </div>
                </div>

                {/* Dashboard Preview with fade transition */}
                <div className="relative w-full lg:w-1/2 animate-[floating_6s_ease-in-out_infinite]">
                    <div className="relative p-2 md:p-4 transition-opacity duration-300">
                        {/* Fixed aspect ratio container */}
                        <div className="relative w-full max-w-full overflow-hidden" style={{ maxHeight: "480px" }}>
                            <div className="aspect-w-16 aspect-h-10 relative">
                                <img 
                                    key={activeContent.image}
                                    src={activeContent.image}
                                    alt={activeContent.imageAlt}
                                    className="w-full h-full object-contain p-2"
                                    style={{ 
                                        maxHeight: "100%",
                                        maxWidth: "100%",
                                    }}
                                />
                            </div>
                            
                            {/* Image indicators - only show for default content */}
                            {!showDeadstock && (
                                <div className="absolute bottom-2 left-0 right-0 flex justify-center gap-2">
                                    {defaultImages.map((_, index) => (
                                        <button 
                                            key={index}
                                            onClick={() => setCurrentImageIndex(index)}
                                            className={`w-2 h-2 rounded-full transition-all duration-300 ${
                                                index === currentImageIndex 
                                                    ? 'bg-primary w-4' 
                                                    : 'bg-gray-300 hover:bg-gray-400'
                                            }`}
                                            aria-label={`View image ${index + 1}`}
                                        />
                                    ))}
                                </div>
                            )}
                        </div>
                        {!userLocation.loading && !userLocation.error && (
                            <div className="text-center mt-4 flex items-center justify-center">
                                <div className="px-4 py-2 bg-gray-50 rounded-full border border-gray-100 shadow-sm flex items-center gap-2 text-sm">
                                    <span className="text-gray-500">Now available in</span>
                                    <ReactCountryFlag 
                                    countryCode={userLocation.country_code}
                                    style={{ fontSize: '1.2em' }}
                                    className="shadow-sm"
                                    />
                                    <span className="font-medium text-gray-700">{userLocation.country}</span>
                                </div>
                            </div>
                        )}
                        
                        {/* Optional subtle gradient overlay */}
                        <div className="absolute inset-0 rounded-lg bg-gradient-to-t from-gray-50/50 to-transparent pointer-events-none" />
                    </div>
                    
                    {/* Decorative background elements */}
                    <div className="absolute -inset-3 bg-gradient-to-tr from-primary/5 via-primary/3 to-transparent rounded-xl blur-xl -z-10" />
                </div>
            </div>
        </Container>
    );
}