import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Container from '../../common/container';

export default function UseCases() {
  const [activeIndustry, setActiveIndustry] = useState('insurance');
  const [isLoading, setIsLoading] = useState(false);

  const handleIndustryChange = (key) => {
    setIsLoading(true);
    setActiveIndustry(key);
    setTimeout(() => setIsLoading(false), 300);
  };

  const sectionVariants = {
    initial: { opacity: 0, y: 20 },
    animate: { 
        opacity: 1, 
        y: 0,
        transition: {
        duration: 0.5,
        staggerChildren: 0.1
        }
    },
    exit: { 
        opacity: 0, 
        y: -20,
        transition: {
        duration: 0.3
        }
    }
    };

  const industries = {
    insurance: {
      icon: "🛡️",
      title: "Insurance",
      description: "Transform policy management and claims processing with intelligent automation",
      capabilities: [
        "Segment customers by profession (doctors, lawyers, teachers) for tailored service",
        "Automate policy inquiries and claims updates",
        "Deploy referral programs with gamification",
        "Send automated renewal reminders and payment links",
        "Collect and verify claim documents via WhatsApp"
      ],
      results: {
        metric1: "60%",
        label1: "Cost Reduction",
        metric2: "80%",
        label2: "Automated Inquiries",
        metric3: "5x",
        label3: "Faster Response"
      }
    },
    finance: {
      icon: "💳",
      title: "Finance",
      description: "Streamline banking services and financial communications",
      capabilities: [
        "Automate balance inquiries and transaction history",
        "Send payment reminders and process payments",
        "Provide loan application status updates",
        "Share investment portfolio updates",
        "Schedule financial advisor consultations"
      ],
      results: {
        metric1: "24/7",
        label1: "Service Availability",
        metric2: "90%",
        label2: "Query Automation",
        metric3: "3min",
        label3: "Response Time"
      }
    },
    hospitality: {
      icon: "🏨",
      title: "Hospitality",
      description: "Enhance guest experience with seamless service automation",
      capabilities: [
        "Manage bookings and check-in/out processes",
        "Coordinate room service and housekeeping",
        "Handle concierge services and local recommendations",
        "Process special requests and amenity bookings",
        "Collect and respond to guest feedback"
      ],
      results: {
        metric1: "300%",
        label1: "Booking Increase",
        metric2: "85%",
        label2: "Query Automation",
        metric3: "95%",
        label3: "Guest Satisfaction"
      }
    },
    travel: {
      icon: "✈️",
      title: "Travel",
      description: "Simplify travel bookings and customer support",
      capabilities: [
        "Automate booking confirmations and itineraries",
        "Send real-time flight updates and changes",
        "Process travel insurance requests",
        "Handle destination inquiries and recommendations",
        "Manage loyalty program communications"
      ],
      results: {
        metric1: "70%",
        label1: "Support Efficiency",
        metric2: "4x",
        label2: "Faster Bookings",
        metric3: "24/7",
        label3: "Support Coverage"
      }
    },
    realestate: {
      icon: "🏠",
      title: "Real Estate",
      description: "Streamline property management and automate rental/sales processes",
      capabilities: [
        "Share property listings with photos and virtual tours",
        "Schedule property viewings and inspections",
        "Automate rent collection and payment reminders",
        "Handle maintenance requests and updates",
        "Process tenant applications and screening"
      ],
      results: {
        metric1: "70%",
        label1: "Faster Leasing",
        metric2: "95%",
        label2: "Inquiry Response",
        metric3: "3x",
        label3: "Lead Conversion"
      }
    },
    auto: {
      icon: "🚗",
      title: "Automotive",
      description: "Streamline vehicle sales and service operations",
      capabilities: [
        "Share vehicle catalogs and specifications",
        "Schedule test drives and service appointments",
        "Send maintenance reminders and updates",
        "Process vehicle inquiries and quotes",
        "Handle after-sales support"
      ],
      results: {
        metric1: "200%",
        label1: "Lead Generation",
        metric2: "75%",
        label2: "Query Automation",
        metric3: "2x",
        label3: "Service Bookings"
      }
    },
    government: {
      icon: "🏛️",
      title: "Government",
      description: "Enhance citizen services and communication",
      capabilities: [
        "Process document requests and applications",
        "Send service updates and notifications",
        "Handle citizen inquiries and complaints",
        "Manage appointment scheduling",
        "Share public service announcements"
      ],
      results: {
        metric1: "50%",
        label1: "Cost Reduction",
        metric2: "95%",
        label2: "Query Resolution",
        metric3: "1min",
        label3: "Response Time"
      }
    },
    nonprofits: {
      icon: "🤝",
      title: "Non-Profits",
      description: "Improve community outreach and program management",
      capabilities: [
        "Coordinate volunteer activities",
        "Process donations and send receipts",
        "Share program updates and impact reports",
        "Manage event registrations",
        "Handle beneficiary communications"
      ],
      results: {
        metric1: "400%",
        label1: "Engagement Rate",
        metric2: "65%",
        label2: "Cost Reduction",
        metric3: "3x",
        label3: "Donor Growth"
      }
    },
    agriculture: {
      icon: "🌾",
      title: "Agriculture",
      description: "Connect farmers with resources and market opportunities",
      capabilities: [
        "Share market prices and weather updates",
        "Coordinate produce collection and delivery",
        "Process orders and payments",
        "Provide farming tips and resources",
        "Handle supplier communications"
      ],
      results: {
        metric1: "80%",
        label1: "Process Automation",
        metric2: "2x",
        label2: "Market Access",
        metric3: "40%",
        label3: "Revenue Growth"
      }
    },
    ecommerce: {
        icon: "🛍️",
        title: "E-commerce",
        description: "Transform online retail with automated shopping experiences and customer support",
        capabilities: [
        "Automate order status updates and tracking",
        "Process product inquiries and send catalogs",
        "Handle returns and refund requests",
        "Send abandoned cart reminders",
        "Manage customer loyalty programs"
        ],
        results: {
        metric1: "85%",
        label1: "Cart Recovery",
        metric2: "3x",
        label2: "Sales Growth",
        metric3: "24/7",
        label3: "Shopping Support"
        }
    },
    education: {
        icon: "🎓",
        title: "Education",
        description: "Transform student engagement and administrative communications through automated messaging",
        capabilities: [
        "Automate student enrollment and registration processes",
        "Send class schedules and assignment reminders",
        "Coordinate parent-teacher communications",
        "Share exam results and progress reports",
        "Manage extracurricular activity signups"
        ],
        results: {
        metric1: "75%",
        label1: "Admin Time Saved",
        metric2: "90%",
        label2: "Response Rate",
        metric3: "3x",
        label3: "Parent Engagement"
        }
    },
  };

  return (
    <motion.section
      initial="initial"
      animate="animate"
      exit="exit"
      variants={sectionVariants}
      className="py-16 lg:py-24 bg-gradient-to-b from-white to-gray-50"
    >
      <Container>
        <motion.div 
          variants={sectionVariants}
          className="text-center mb-12"
        >
          <h2 className="text-3xl md:text-4xl font-bold mb-4">
            Industry Solutions
          </h2>
          <p className="text-gray-600 text-lg">
            Tailored automation for every sector
          </p>
        </motion.div>

        {/* Progress Bar */}
        <motion.div variants={sectionVariants} className="max-w-3xl mx-auto mb-8">
          <div className="w-full h-1 bg-gray-100 rounded-full">
            <motion.div 
              className="h-full bg-primary rounded-full transition-all duration-300"
              style={{ 
                width: `${(Object.keys(industries).indexOf(activeIndustry) + 1) * (100/Object.keys(industries).length)}%` 
              }}
            />
          </div>
        </motion.div>

        {/* Improved Tab Navigation */}
        <motion.div 
          variants={sectionVariants}
          className="relative max-w-6xl mx-auto px-4 md:px-6"
        >
          {/* Mobile View - Horizontal Scrollable List */}
          <div className="md:hidden relative">
            {/* Outer container to hide scrollbar */}
            <div className="relative overflow-hidden -mx-4">
              {/* Inner scrollable container */}
              <div 
                className="flex gap-3 pb-4 px-4 overflow-x-auto snap-x snap-mandatory hide-scrollbar"
                style={{
                  WebkitOverflowScrolling: 'touch',
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none'
                }}
              >
                {Object.entries(industries).map(([key, industry]) => (
                  <button
                    key={key}
                    onClick={() => handleIndustryChange(key)}
                    className={`flex flex-col items-center snap-center
                      min-w-[100px] p-3 rounded-xl transition-all
                      ${activeIndustry === key 
                        ? 'bg-primary/10 text-primary shadow-sm ring-2 ring-primary/20' 
                        : 'bg-gray-50/80 text-gray-600 hover:bg-gray-50'
                      }`}
                  >
                    <span className="text-2xl mb-2">{industry.icon}</span>
                    <span className="text-sm font-medium text-center leading-tight">
                      {industry.title}
                    </span>
                    
                    {activeIndustry === key && (
                      <motion.div 
                        layoutId="mobileActiveIndicator"
                        className="absolute bottom-0 left-1/2 -translate-x-1/2 w-12 h-0.5 bg-primary rounded-full"
                      />
                    )}
                  </button>
                ))}
              </div>

              {/* Enhanced Scroll Indicators */}
              <div className="absolute left-0 top-0 bottom-4 w-8 bg-gradient-to-r from-white via-white to-transparent pointer-events-none" />
              <div className="absolute right-0 top-0 bottom-4 w-8 bg-gradient-to-l from-white via-white to-transparent pointer-events-none" />
            </div>
          </div>

          {/* Desktop View - Wrapped Flex Layout */}
          <div className="hidden md:flex flex-wrap justify-center gap-4 mb-12">
            {Object.entries(industries).map(([key, industry]) => (
              <div key={key} className="relative group">
                <button
                  onClick={() => handleIndustryChange(key)}
                  className={`flex items-center gap-2 px-4 py-2 rounded-full transition-all relative ${
                    activeIndustry === key 
                      ? 'bg-primary/5 text-primary' 
                      : 'hover:bg-gray-100 text-gray-500 hover:text-gray-700'
                  }`}
                >
                  <span className="text-xl">{industry.icon}</span>
                  <span className="font-medium whitespace-nowrap">{industry.title}</span>
                  
                  {/* Active Indicator */}
                  {activeIndustry === key && (
                    <motion.div 
                      layoutId="desktopActiveIndicator"
                      className="absolute inset-0 border-2 border-primary rounded-full"
                    />
                  )}
                </button>

                {/* Tooltip - Desktop Only */}
                <div className="absolute hidden group-hover:block top-full left-1/2 -translate-x-1/2 mt-2 p-3 bg-white rounded-lg shadow-lg z-10 w-48">
                  <p className="text-sm text-gray-600">{industry.description}</p>
                  <div className="absolute -top-2 left-1/2 -translate-x-1/2 w-4 h-4 bg-white transform rotate-45" />
                </div>
              </div>
            ))}
          </div>
        </motion.div>

        {/* Content Section */}
        <AnimatePresence mode="wait">
          <motion.div 
            key={activeIndustry}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: isLoading ? 0.5 : 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="grid lg:grid-cols-2 gap-12 items-start"
          >
            {/* Capabilities Section */}
            <div className="bg-white p-6 md:p-8 rounded-2xl shadow-lg">
              <div className="flex items-center gap-4 mb-8">
                <span className="text-4xl">{industries[activeIndustry].icon}</span>
                <div>
                  <h3 className="text-2xl font-bold">{industries[activeIndustry].title}</h3>
                  <p className="text-gray-600">{industries[activeIndustry].description}</p>
                </div>
              </div>

              <div className="space-y-6">
                {industries[activeIndustry].capabilities.map((capability, index) => (
                  <motion.div 
                    key={capability}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="bg-gray-50 p-4 rounded-lg hover:bg-gray-100 transition-all"
                  >
                    <div className="flex items-start gap-3">
                      <div className="flex-shrink-0 w-6 h-6 mt-1 rounded-full bg-primary/10 flex items-center justify-center">
                        <svg className="w-4 h-4 text-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                        </svg>
                      </div>
                      <div>
                        <p className="text-gray-700">{capability}</p>
                        {index < industries[activeIndustry].capabilities.length - 1 && (
                          <div className="absolute left-3 w-px h-6 bg-primary/10" />
                        )}
                      </div>
                    </div>
                  </motion.div>
                ))}
              </div>
            </div>

            {/* Results Cards */}
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {Object.entries(industries[activeIndustry].results).map(([key, value], index) => (
                index % 2 === 0 && (
                  <motion.div 
                    key={key}
                    whileHover={{ scale: 1.05 }}
                    className="bg-white p-6 rounded-xl shadow-lg"
                  >
                    <div className="flex items-center justify-between mb-3">
                      <motion.div 
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        className="text-3xl font-bold text-primary"
                      >
                        {value}
                      </motion.div>
                      <span className="text-green-500 text-sm">↑ Growth</span>
                    </div>
                    <div className="text-gray-600 text-sm">
                    {industries[activeIndustry].results[`label${Math.floor(index/2) + 1}`]}
                    </div>
                  </motion.div>
                )
              ))}
            </div>
          </motion.div>
        </AnimatePresence>
      </Container>
    </motion.section>
  );
}